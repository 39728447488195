// This service is based on the `ng2-cookies` package which sadly is not a service and does
// not use `DOCUMENT` injection and therefore doesn't work well with AoT production builds.
// Package: https://github.com/BCJTI/ng2-cookies


import { Injectable, Inject, PLATFORM_ID, InjectionToken } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
export class CookieService {
private readonly documentIsAccessible: boolean;
/**
 * @param {?} document
 * @param {?} platformId
 */
constructor(
private document: any,
private platformId: InjectionToken<Object>,
  ) {
    this.documentIsAccessible = isPlatformBrowser( this.platformId );
  }
/**
 * @param {?} name Cookie name
 * @return {?}
 */
check( name: string ): boolean {
    if ( !this.documentIsAccessible ) {
      return false;
    }

    name = encodeURIComponent( name );

    const /** @type {?} */ regExp: RegExp = this.getCookieRegExp( name );
    const /** @type {?} */ exists: boolean = regExp.test( this.document.cookie );

    return exists;
  }
/**
 * @param {?} name Cookie name
 * @return {?}
 */
get( name: string ): string {
    if ( this.documentIsAccessible && this.check( name ) ) {
      name = encodeURIComponent( name );

      const /** @type {?} */ regExp: RegExp = this.getCookieRegExp( name );
      const /** @type {?} */ result: RegExpExecArray = regExp.exec( this.document.cookie );

      return decodeURIComponent( result[ 1 ] );
    } else {
      return '';
    }
  }
/**
 * @return {?}
 */
getAll(): {} {
    if ( !this.documentIsAccessible ) {
      return {};
    }

    const /** @type {?} */ cookies: {} = {};
    const /** @type {?} */ document: any = this.document;

    if ( document.cookie && document.cookie !== '' ) {
      const /** @type {?} */ split: Array<string> = document.cookie.split(';');

      for ( let /** @type {?} */ i = 0; i < split.length; i += 1 ) {
        const /** @type {?} */ currentCookie: Array<string> = split[ i ].split('=');

        currentCookie[ 0 ] = currentCookie[ 0 ].replace( /^ /, '' );
        cookies[ decodeURIComponent( currentCookie[ 0 ] ) ] = decodeURIComponent( currentCookie[ 1 ] );
      }
    }

    return cookies;
  }
/**
 * @param {?} name     Cookie name
 * @param {?} value    Cookie value
 * @param {?=} expires  Number of days until the cookies expires or an actual `Date`
 * @param {?=} path     Cookie path
 * @param {?=} domain   Cookie domain
 * @param {?=} secure   Secure flag
 * @param {?=} sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `None`
 * @return {?}
 */
set(
    name: string,
    value: string,
    expires?: number | Date,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite: 'Lax' | 'None' | 'Strict' = 'None'
  ): void {
    if ( !this.documentIsAccessible ) {
      return;
    }

    let /** @type {?} */ cookieString: string = encodeURIComponent( name ) + '=' + encodeURIComponent( value ) + ';';

    if ( expires ) {
      if ( typeof expires === 'number' ) {
        const /** @type {?} */ dateExpires: Date = new Date( new Date().getTime() + expires * 1000 * 60 * 60 * 24 );

        cookieString += 'expires=' + dateExpires.toUTCString() + ';';
      } else {
        cookieString += 'expires=' + expires.toUTCString() + ';';
      }
    }

    if ( path ) {
      cookieString += 'path=' + path + ';';
    }

    if ( domain ) {
      cookieString += 'domain=' + domain + ';';
    }

    if ( secure ) {
      cookieString += 'secure;';
    }

    cookieString += 'sameSite=' + sameSite + ';';

    this.document.cookie = cookieString;
  }
/**
 * @param {?} name   Cookie name
 * @param {?=} path   Cookie path
 * @param {?=} domain Cookie domain
 * @return {?}
 */
delete( name: string, path?: string, domain?: string ): void {
    if ( !this.documentIsAccessible ) {
      return;
    }

    this.set( name, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), path, domain, undefined, 'Lax' );
  }
/**
 * @param {?=} path   Cookie path
 * @param {?=} domain Cookie domain
 * @return {?}
 */
deleteAll( path?: string, domain?: string ): void {
    if ( !this.documentIsAccessible ) {
      return;
    }

    const /** @type {?} */ cookies: any = this.getAll();

    for ( const /** @type {?} */ cookieName in cookies ) {
      if ( cookies.hasOwnProperty( cookieName ) ) {
        this.delete( cookieName, path, domain );
      }
    }
  }
/**
 * @param {?} name Cookie name
 * @return {?}
 */
private getCookieRegExp( name: string ): RegExp {
    const /** @type {?} */ escapedName: string = name.replace( /([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/ig, '\\$1' );

    return new RegExp( '(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g' );
  }
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: undefined, decorators: [{ type: Inject, args: [DOCUMENT, ] }, ]},
{type: InjectionToken, decorators: [{ type: Inject, args: [PLATFORM_ID, ] }, ]},
];
}

function CookieService_tsickle_Closure_declarations() {
/** @type {?} */
CookieService.decorators;
/**
 * @nocollapse
 * @type {?}
 */
CookieService.ctorParameters;
/** @type {?} */
CookieService.prototype.documentIsAccessible;
/** @type {?} */
CookieService.prototype.document;
/** @type {?} */
CookieService.prototype.platformId;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
